import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  Router,
  Redirect,
} from 'react-router-dom';
import { PageNotFound, Unauthorized } from '~/presentation/pages/errors/index';
import { History } from '.';
import { PrescriptionFactory } from '../factories/pages/prescription';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Router history={History.getHistory()}>
        <Switch>
          <Route exact path="/receita" component={PrescriptionFactory} />
          <Route
            exact
            path="/receita/Error"
            component={() => <div>Erro no login</div>}
          />
          <Route
            exact
            path="/receita/401unauthorized"
            component={Unauthorized}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
};

export default Routes;
